import { faCalendar, faCreditCard, faLocationDot, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BulletPoint from '../components/about/bullet';
import Layout from '../components/layout';
import PageSection from '../components/page-section';
import Seo from '../components/seo';
import ClientOnly from '../components/client-only';
import ToursList from '../components/tour-calendar-list/list/ToursList';

import { useDispatch } from "react-redux"
import { Dispatch } from "../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ToursCalendarList from '../components/tour-calendar-list/tour-calendar-list';
import ToursDaysList from '../components/tour-calendar-list/tours-days-list';

const VistulaDayTicketsAboutPage = () => {

    return (
        <Layout>
            <Seo title={"Podchody wiślane 2024 - Bilety"} />
            <PageSection>
                <h1><strong>Motołódkowe podchody</strong><br />po Dzielnicy Wisła 2024</h1>
                <div className="py-3"></div>
                <h3>Rezerwacja miejsc na bezpłatny wyścig nawigacyjny po Wiśle. W podchodach mogą wziąć udział osoby dorosłe oraz dzieci pod opieką dorosłych.</h3>
                <div className="py-3"></div>
                <p>Zbierz swoją drużynę składającą się z 2-4 osób.
                    Podzielcie się rolami: sternika, nawigatora, obserwatora, kapitana.
                    🔹 Sterniku, przeprowadź bezpiecznie łódkę prze płytkie wody Wisły.
                    🔹 Nawigatorze – dostaniesz mapę i busolę, prowadź do punktu!
                    🔹 Obserwatorze – rozpoznaj obiekty ze zdjęć, ustal, gdzie zostały zrobione. Razem z nawigatorem nanieś te punkty na mapę.
                    🔹 Kapitanie – kieruj rozgrywką, opracuj strategię i razem z całą załogą rozwiąż zagadki.
                    🏃‍♀️ Zróbcie to jak najszybciej! Dodatkowo, za każdy odnaleziony punkt/zrealizowane zadanie dostaniecie bonus czasowy. Zwycięża ten, kto po uwzględnieniu bonusów będzie miał najkrótszy czas.
                    Szykuje się pyszna zabawa.
                    📍 Startujemy z Płyty Desantu (przystań motołódek obok Pomostu511) o 12.00.
                    📍Na godz. 15:30 planujemy dekorację zwycięzców, nagrody, brawa, splendor.
                    👨‍👩‍👧‍👦 W podchodach mogą wziąć udział osoby dorosłe oraz dzieci pod opieką osoby dorosłej.
                    Nasz wyścig towarzyszyć będzie Międzynarodowemu Turniejowi Padla, który odbędzie się tego samego dnia na Płycie Desantu. Możecie więc mieć nie lada publiczność.
                    Po zakończeniu wyścigu będziecie mogli też zostać podziwiać gwiazdorski mecz pokazowy. </p>
                <div className="py-3"></div>
                <div className="pt-3">
                    <a href="#booking-section" type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj</a>
                    <span className="ps-3"></span>
                    <a id="read-more-tickets" href="#more-about-tickets-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                </div>
            </PageSection>
            <PageSection className='bg-white text-black' id="more-about-tickets-section" data-bs-spy="scroll" data-bs-target="#read-more-tickets">
                <div className="container-fluid px-4 px-md-0 py-2">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-6">
                                    <BulletPoint icon={faLocationDot} title={"Wymagania"} >
                                        <span>Na wydarzenie należy przybyć <strong>najpóźniej 10 min przed terminem rozpoczęcia</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCalendar} title={"Otwarcie rezerwacji"} >
                                        <span>Otwarcie zapisów w poniedziałek 9 sierpnia 2024 o godzinie 21:00.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faPeopleLine} title={"Ograniczenia"} >
                                        <span>Jedna osoba może złożyć rezerwację na grupę <strong>maks. 4 osób</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCreditCard} title={"Kaucja zwrotna"} >
                                        <span>Za każdego uczestnika zajęć pobieramy kaucję zwrotną w wysokości <br /><strong>13zł /os</strong>.</span>
                                    </BulletPoint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
            <PageSection id="booking-section" data-bs-spy="scroll" data-bs-target="#booking-section">
                <h1 className="pb-5">Zapisy online</h1>
                <div className="py-2" ></div>
                {/* <p>Poniżej znajduje się termin na który można zarezerwować miejsca.</p> */}
            </PageSection>
            <PageSection id="toursCalendarSection" className="bg-white text-black position-relative" style={{ minHeight: 400 }}>
                <ClientOnly>
                    <ToursDaysList project="POD_24" />
                </ClientOnly>
            </PageSection>
        </Layout>
    );
}

export default VistulaDayTicketsAboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;